import { CheckIcon, PlayIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState, Suspense } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate, useLocation } from "react-router-dom";
import { usePlayer } from "./App";
import { api } from "./api.service";
import HelpPopup from "./components/help-popup";
import ImageViewer from "./components/image-viewer";
import GlobeIcons from "./components/globe-icons";
import { toast } from "react-toastify";
import { countries, countriesByContinent } from "constants/constants";
import Flag from "react-flagkit";
import { useMediaQuery } from "react-responsive";
import SongCard from "components/song-card";
import HorizontalScrollContainer from "components/horizontal-scroll";

export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const [data, setData] = useState();
  const [dataByGroup, setDataByGroup] = useState();
  const [charts, setCharts] = useState();
  const [networkSongs, setNetworkSongs] = useState();
  const player = usePlayer();
  const [shuffledTopSongs, setShuffledTopSongs] = useState([]);
  const [shuffledTopSongs2, setShuffledTopSongs2] = useState([]);
  const [shuffled1Songs, setShuffled1Songs] = useState([]);
  const [genrePreview, setGenrePreview] = useState();
  const [loggedIn, setLoggedIn] = useState(true);
  const [requests, setRequests] = useState();
  const [welcomeMessage, setWelcomeMessage] = useState();
  const [welcomeFlag, setWelcomeFlag] = useState();
  const [welcomeCountry, setWelcomeCountry] = useState();

  const [ignoredAbout, setIgnoredAbout] = useState(
    localStorage.getItem("kigab")
  );

  useEffect(() => {
    let checkCount = 0;
    const navigateToFragment = () => {
      if (!location?.state) return;
      const { id } = location?.state;
      if (id && document !== null) {
        const el = document.getElementById(id);
        if (!el) {
          console.warn(
            "Couldn't find fragment with that ID to show. Trying again"
          );
          checkCount++;
          checkCount < 4
            ? setTimeout(navigateToFragment, 100)
            : console.warn(
                "Exceeded check threshold, failed to locate element."
              );
          return;
        }
        if (isMobile) {
          el.style.scrollMarginTop = "141px";
        }
        el.scrollIntoView({
          behavior: "instant",
          block: "start",
          inline: "nearest"
        });
        window.history.replaceState({}, "");
        console.log("navigating to fragment", id);
      }
    };
    navigateToFragment();
  }, [location, dataByGroup]);

  let genres = [];

  const acceptRequest = id => {
    api(`${process.env.REACT_APP_API_URL}/user/accept-request/${id}`)
      .then(x => {
        api(`${process.env.REACT_APP_API_URL}/user/get-requests-home`).then(
          x => {
            setRequests(x);
          }
        );
      })
      .then(x => {
        toast.success("Friend request accepted");
      });
  };

  const rejectRequest = id => {
    api(`${process.env.REACT_APP_API_URL}/user/reject-request/${id}`).then(
      x => {
        api(`${process.env.REACT_APP_API_URL}/user/get-requests-home`).then(
          x => {
            setRequests(x);
          }
        );
      }
    );
  };

  const clickIgnoreAbout = () => {
    setIgnoredAbout(true);
    localStorage.setItem("kigab", "true");
  };

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  const CustomDot = ({
    onMove,
    index,
    onClick,
    active,
    currentSlide,
    totalItems
  }) => {
    return (
      <li
        className={
          (active ? "bg-white" : "bg-none") +
          " mx-1 sm:w-2 sm:h-2 w-1 h-1 border rounded-full"
        }
        onClick={() => onClick()}
      >
        <div className=""></div>
      </li>
    );
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems }
    } = rest;
    const dotSize = 8;
    // margin between dots is 8px -> mr-1 on span.
    const dotsToShow = 10;
    /*
      NOTE react multi carousel pads the first two and the last two items
      in the carousel to create an infinite scroll effect.
      The first 2 items get added to the end and the
      last 2 items get added to the start.
    */
    const totalItemsSansPadding = totalItems - 4;
    // keep active dot centered
    const containerTranslate =
      currentSlide - 2 < Math.floor(totalItemsSansPadding / 4)
        ? 0
        : (currentSlide - Math.floor(totalItemsSansPadding / 4)) *
          (dotSize + 8);
    return (
      <div
        className={
          "carousel-button-group absolute overflow-hidden " +
          "bottom-0 left-2/4 -translate-x-1/2"
        }
        style={{
          width: `${(dotSize + 8) * dotsToShow}px`,
          height: `${dotSize * 2}px`
        }}
      >
        <div
          className="carousel-button-group__container flex flex-row absolute left-0 transition-all duration-500"
          style={{ transform: `translateX(${-containerTranslate}px)` }}
        >
          {new Array(totalItemsSansPadding).fill(0).map((val, index) => {
            return (
              <span
                onClick={() => goToSlide(index)}
                key={index}
                className={
                  (index === currentSlide - 2 ||
                  (index === 0 && currentSlide - 2 < 0)
                    ? "bg-white  border border-white"
                    : `bg-gray-500 opacity-${Math.max(
                        0,
                        100 - 40 * Math.abs(index - (currentSlide - 2))
                      )}`) +
                  " mx-1 sm:w-2 sm:h-2 w-1 h-1 mr-1 rounded-full block cursor:pointer " +
                  `${
                    index !== 0 && Math.abs(index - (currentSlide - 2)) < 1
                      ? "opacity-100"
                      : "opacity-20 scale-80"
                  }`
                }
              >
                <div className=""></div>
              </span>
            );
          })}
        </div>
      </div>
    );
  };

  const responsive = {
    superLargeDesktop2: {
      breakpoint: { max: 10000, min: 1580 },
      items: 1
    },
    superLargeDesktop: {
      breakpoint: { max: 1580, min: 1280 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1280, min: 786 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 786, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const getSongGroup = song => {
    return countriesByContinent[song.chart.toLowerCase().replace(" ", "")];
  };
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/public/home`).then(x => {
      setData(x);
      setShuffledTopSongs(
        shuffleArray([...x.songs.filter(x => x.rank > 1 && x.rank < 5)])
      );
      setShuffledTopSongs2(
        shuffleArray([...x.songs.filter(x => x.rank > 51 && x.rank < 55)])
      );
      setShuffled1Songs(shuffleArray([...x.songs.filter(x => x.rank === 1)]));
      const groupedData = x.songs
        .filter(x => x !== "Other")
        .map(song => {
          const group = getSongGroup(song);
          return {
            ...song,
            group
          };
        });

      const byContientAndCountry = groupedData.reduce((acc, curr) => {
        const country = curr.chart;
        if (!acc[curr.group]) {
          acc[curr.group] = {};
        }
        if (!acc[curr.group][country]) {
          acc[curr.group][curr.chart] = [];
        }
        const countryKey = curr.group.toLowerCase().replace(" ", "-");
        if (countryKey !== "global") {
          const shortCode = countries[countryKey].find(
            countryObj => countryObj.countryLabel === country
          ).shortCode;

          curr.shortCode = shortCode;
        }
        acc[curr.group][country].push(curr);
        return acc;
      }, {});
      setDataByGroup(byContientAndCountry);
    });
  }, []);
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/public/charts`).then(x => {
      setCharts(x.charts);
      genres = x.charts;
    });
  }, []);
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/public/genre/preview`)
      .then(x => {
        setGenrePreview(x);
      })
      .then
      // console.log("genrePreview")
      ();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      api(`${process.env.REACT_APP_API_URL}/user/network`).then(x => {
        setNetworkSongs(x);
      });
      api(`${process.env.REACT_APP_API_URL}/user/welcome-message`).then(x => {
        setWelcomeMessage(x.message.welcome);
        setWelcomeFlag(x.message.code);
        setWelcomeCountry(x.message.country);
      });
      api(`${process.env.REACT_APP_API_URL}/user/get-requests-home`).then(x => {
        setRequests(x);
      });
    } else {
      api(`${process.env.REACT_APP_API_URL}/public/welcome-message`).then(x => {
        setWelcomeMessage(x.message.welcome);
        setWelcomeFlag(x.message.code);
        setWelcomeCountry(x.message.country);
      });
      setLoggedIn(false);
    }
  }, []);

  const getYoutubeVideoId = url => {
    return url?.split("v=")[1];
  };

  const handleTop10ChartClick = (e, song) => {
    e.stopPropagation();
    player.setSong({
      ...song,
      id: getYoutubeVideoId(song.url)
    });
    player.ChangeMode("Chart", song.chart);
  };

  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  return (
    <Suspense fallback={<p>Loading</p>}>
      <div className="flex flex-col h-full">
        <div className="flex flex-col w-full max-w-7xl mx-auto">
          <div className="flex items-center">
            <Flag
              country={welcomeFlag}
              size={50}
              className={`${welcomeFlag ?? "hidden"} pr-4 inline-block`}
            ></Flag>
            <div className="my-8 text-4xl font-black text-white">{`${welcomeMessage ??
              "Welcome"}`}</div>
          </div>

          {!ignoredAbout && (
            <>
              <div className="hidden relative p-5 my-5 text-center text-white border rounded-xl bg-gradient-to-br from-neutral-600 to-neutral-700">
                <div
                  onClick={
                    () =>
                      clickIgnoreAbout() /* USE THIS AREA FOR HOME PAGE POPUPS (remove hidden in first div)*/
                  }
                  className="absolute cursor-pointer right-5"
                >
                  <XMarkIcon className="w-8 h-8 transform hover:text-neutral-800"></XMarkIcon>
                </div>
                <div className="mb-5 text-2xl font-bold">About KaZang</div>
                <p className="my-1 mt-5 font-medium text-light">
                  Welcome to KaZang – a music discovery platform that helps
                  people find music they love in languages they don’t speak.
                </p>
                <p className="my-1 font-medium text-light">
                  KaZang breaks barriers to build bridges. We cross frontiers to
                  open minds. We look beyond the horizon to discover ourselves.
                  We explore the world through music.
                </p>
                <div
                  className="text-purple-300 underline cursor-pointer"
                  onClick={() => navigate("/about")}
                >
                  Read More...
                </div>
              </div>
            </>
          )}
          {requests?.length > 0 && (
            <div className="my-2 mt-5 text-xl font-medium text-white">
              Friend Requests
            </div>
          )}
          {requests?.map(x => (
            <div
              onClick={() => navigate("/profile/" + x.requester._id)}
              className="flex items-center p-2 space-x-2 cursor-pointer hover:bg-neutral-100/10 "
            >
              {x.requester.profilePicture && (
                <div>
                  <ImageViewer
                    className="w-12 h-12 rounded-full"
                    image={x.requester.profilePicture}
                  ></ImageViewer>
                </div>
              )}
              <div className="mr-5">
                <div className="text-xl font-semibold text-white">
                  {x.requester.name}
                </div>
                <div className="text-sm font-light text-light">
                  {x.requester.bio}
                </div>
              </div>
              <div className="text-light">
                <div className="flex space-x-4">
                  <div
                    className="w-10 h-10 p-1 bg-green-700 rounded-full shadow"
                    onClick={e => {
                      e.stopPropagation();
                      acceptRequest(x._id);
                    }}
                  >
                    <CheckIcon className="inline hover:opacity-70"></CheckIcon>
                  </div>
                  <div
                    className="w-10 h-10 p-1 bg-red-700 rounded-full shadow"
                    onClick={e => {
                      e.stopPropagation();
                      rejectRequest(x._id);
                    }}
                  >
                    <XMarkIcon className="inline hover:opacity-70"></XMarkIcon>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* Old number one song banner */}
          {/* <div className="flex">
        <div className="my-2 mt-5 text-xl font-medium text-white">Number 1 Songs</div>
        <div className="relative">
          <HelpPopup text="Number 1 Songs features the songs that are Number 1 (by views) on YouTube’s charts for each of the 25 countries featured on KaZang."></HelpPopup>

        </div>
      </div> */}

          <div className="relative flex w-full overflow-hidden">
            <div className="w-full max-h-2000 mx-auto mb-2">
              {data?.songs?.length > 0 && (
                <>
                  <div
                    className={
                      "max-md:hidden " +
                      "absolute w-full h-full top-0 left-0 " +
                      "bg-gradient-to-r from-opaque-60 from-20% via-transparent via-30% to-opaque-60 to-80% " +
                      "z-10 pointer-events-none"
                    }
                  ></div>
                  <Carousel
                    customButtonGroup={<ButtonGroup />}
                    arrows={false}
                    infinite={true}
                    itemClass="pb-16"
                    autoPlay={true}
                    centerMode={!isMobile}
                    removeArrowOnDeviceType={[
                      "superLargeDesktop2",
                      "superLargeDesktop",
                      "desktop",
                      "tablet",
                      "mobile"
                    ]}
                    autoPlaySpeed={4000}
                    showDots={false}
                    responsive={responsive}
                    containerClass=""
                  >
                    {shuffled1Songs.map((x, i) => (
                      <div
                        key={x.url + i}
                        id={i}
                        onClick={e => {
                          e.stopPropagation();
                          player.setSong({
                            ...x,
                            id: getYoutubeVideoId(x.url)
                          });
                          player.ChangeMode("Random", "");
                        }}
                        className={`
                          relative overflow-hidden transition
                          duration-300 rounded shadow
                          cursor-pointer select-none group
                          bg-neutral-700 hover:bg-neutral-600`}
                      >
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            player.setSong({
                              ...x,
                              id: getYoutubeVideoId(x.url)
                            });
                          }}
                          className={`
                            absolute p-2 transition
                            duration-300 scale-100 bg-purple-400
                            rounded-full shadow opacity-100
                            cursor-pointer bottom-4 right-4
                            lg:opacity-0 group-hover:opacity-100
                            lg:scale-50 group-hover:scale-100 hover:bg-purple-300
                            `}
                        >
                          <PlayIcon className="w-8 text-white" />
                        </div>

                        <div className="relative flex">
                          <img
                            alt=""
                            style={{ aspectRatio: "16/9" }}
                            className="object-cover w-full mx-auto rounded h-full"
                            src={`https://i.ytimg.com/vi_webp/${getYoutubeVideoId(
                              x.url
                            )}/sddefault.webp`}
                          ></img>

                          <div className="absolute inset-0 p-2 pointer-events-none select-none bg-gradient-to-r from-neutral-900/60 to-neutral-50/0">
                            <div className="flex flex-col justify-between h-full p-2">
                              <div className="text-4xl font-semibold tracking-wider text-light">
                                {x.chart} No. 1
                              </div>
                              <div className="pb-5">
                                <div className="mt-1 text-2xl font-semibold text-white truncate">
                                  {x.name}
                                </div>
                                <div className="text-lg font-medium truncate text-light">
                                  {x.artist}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </>
              )}
            </div>
          </div>

          <div className="flex">
            <div className="my-2 mt-5 text-xl font-medium text-white">
              Trending Songs
            </div>
            <div className="relative">
              <HelpPopup text="Trending Songs showcase songs selected from the YouTube charts outside of the top songs chart"></HelpPopup>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
            {shuffledTopSongs2
              ?.filter((x, i) => i < 6)
              .map((x, i) => (
                <div
                  key={x + i}
                  onClick={e => {
                    e.stopPropagation();
                    player.setSong({ ...x, id: getYoutubeVideoId(x.url) });
                    player.ChangeMode("Random", "");
                  }}
                  className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600"
                >
                  <div
                    onClick={e => {
                      e.stopPropagation();
                      player.setSong({ ...x, id: getYoutubeVideoId(x.url) });
                      player.ChangeMode("Random", "");
                    }}
                    className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300"
                  >
                    <PlayIcon className="w-8 text-white" />
                  </div>
                  <div className="p-2 text-lg font-semibold tracking-wider text-light">
                    {x.chart}
                  </div>
                  <div className="flex p-2">
                    <img
                      alt=""
                      className="object-cover rounded"
                      src={`https://img.youtube.com/vi/${getYoutubeVideoId(
                        x.url
                      )}/mqdefault.jpg`}
                    ></img>
                  </div>
                  <div className="p-2">
                    <div className="mt-1 text-base font-semibold text-white truncate">
                      {x.name}
                    </div>
                    <div className="text-xs font-medium truncate text-light">
                      {x.artist}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {networkSongs
            ?.filter(x => data?.songs?.find(y => y._id === x.song))
            .filter(
              (x, i) => data?.songs?.find(y => y._id === x.song).rank < 101
            ).length > 0 && (
            <div className="my-2 mt-5 text-xl font-medium text-white">
              Popular In Your Network
            </div>
          )}
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
            {networkSongs
              ?.filter(x => data?.songs?.find(y => y._id === x.song))
              .filter(
                (x, i) => data?.songs?.find(y => y._id === x.song).rank < 101
              )
              .filter((x, i) => i < 10)
              .map(x => (
                <div>
                  <div
                    onClick={e => {
                      e.stopPropagation();
                      player.setSong({ ...x, id: getYoutubeVideoId(x.url) });
                    }}
                    className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600"
                  >
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        player.setSong({
                          ...data?.songs?.find(y => y._id === x.song),
                          id: getYoutubeVideoId(
                            data?.songs?.find(y => y._id === x.song)?.url
                          )
                        });
                      }}
                      className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300"
                    >
                      <PlayIcon className="w-8 text-white" />
                    </div>
                    <div className="p-2 text-lg font-semibold tracking-wider text-light">
                      {data?.songs?.find(y => y._id === x.song)?.chart}
                    </div>
                    <div className="flex p-2">
                      <img
                        alt=""
                        className="object-cover rounded"
                        src={`https://img.youtube.com/vi/${getYoutubeVideoId(
                          data?.songs?.find(y => y._id === x.song)?.url
                        )}/mqdefault.jpg`}
                      ></img>
                    </div>
                    <div className="p-2">
                      <div className="mt-1 text-base font-semibold text-white truncate">
                        {data?.songs?.find(y => y._id === x.song)?.name}
                      </div>
                      <div className="text-xs font-medium truncate text-light">
                        {data?.songs?.find(y => y._id === x.song)?.artist}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {genrePreview &&
            Object.keys(genrePreview.songs).map((genreLabel, index) => (
              <div key={genreLabel + index}>
                <div
                  className="flex items-center justify-between"
                  onClick={() => navigate("/genre/" + genreLabel.toLowerCase())}
                >
                  <div
                    className="my-2 mt-5 text-xl font-medium text-white"
                    id={genreLabel.toLowerCase()}
                  >
                    {genreLabel.toUpperCase()}
                  </div>
                  <b className="px-3 py-2 text-sm font-bold text-white bg-purple-500 rounded  cursor-pointer hover:underline">
                    More Songs
                  </b>
                </div>
                <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
                  {genrePreview &&
                    shuffleArray(genrePreview.songs[genreLabel])
                      .slice(0, 10)
                      .map((y, i) => (
                        <div
                          key={y.url + i}
                          onClick={e => {
                            e.stopPropagation();
                            player.setSong({
                              ...y,
                              id: getYoutubeVideoId(y.url)
                            });
                            player.ChangeMode("Genre", genreLabel);
                          }}
                          className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600"
                        >
                          <div
                            onClick={e => {
                              e.stopPropagation();
                              player.setSong({
                                ...y,
                                id: getYoutubeVideoId(y.url)
                              });
                            }}
                            className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300"
                          >
                            <PlayIcon className="w-8 text-white" />
                          </div>
                          <div className="p-2 text-lg font-semibold tracking-wider text-light">
                            {" "}
                            {y.chart}
                          </div>

                          <div className="flex p-2">
                            <img
                              alt=""
                              className="object-cover rounded"
                              src={`https://img.youtube.com/vi/${getYoutubeVideoId(
                                y.url
                              )}/mqdefault.jpg`}
                            ></img>
                          </div>
                          <div className="p-2">
                            <div className="mt-1 text-base font-semibold text-white truncate">
                              {y.name}
                            </div>
                            <div className="text-xs font-medium truncate text-light">
                              {y.artist}
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            ))}

          <div className="flex">
            <div className="my-2 mt-5 text-xl font-medium text-white">
              Popular Songs
            </div>
            <div className="relative">
              <HelpPopup text="Popular Songs are drawn from YouTube’s “Top Songs” chart. This chart ranks songs by the number of YouTube views. The count combines views for the official music video, for user-generated content using the original song, and videos featuring the song and its lyrics."></HelpPopup>
            </div>
          </div>

          {dataByGroup &&
            Object.keys(dataByGroup).map((_key, index) => {
              return (
                <div key={_key + index}>
                  <div className="flex gap-2 items-center mt-10">
                    {_key.toLowerCase() !== "global" && (
                      <GlobeIcons
                        icon={_key.toLowerCase().replace(" ", "-")}
                        className="-ml-2"
                      />
                    )}
                    <h1 className="text-white text-4xl font-bold">
                      {_key.toUpperCase()}
                    </h1>
                  </div>
                  {Object.keys(dataByGroup[_key]).map((countryKey, index) => {
                    return (
                      <div
                        key={countryKey.toLowerCase() + index}
                        className="py-4"
                      >
                        <div
                          className="flex items-center justify-between"
                          id={countryKey.toLowerCase()}
                        >
                          <div className="my-2 mt-5 text-2xl font-medium text-white cursor-pointer flex gap-2 items-center">
                            <Flag
                              country={
                                dataByGroup[_key][countryKey][0].shortCode
                              }
                            />
                            {countryKey} Top 10
                          </div>
                          <b
                            className="px-3 py-2 text-sm font-bold text-white bg-purple-500 rounded cursor-pointer hover:underline"
                            onClick={() =>
                              navigate("/chart/" + countryKey.toLowerCase())
                            }
                          >
                            More songs
                          </b>
                        </div>
                        <HorizontalScrollContainer>
                          {dataByGroup[_key][countryKey]
                            .sort((a, b) => a.rank - b.rank)
                            .slice(0, 10)
                            .map((song, index) => (
                              <SongCard
                                key={song.url + index}
                                song={song}
                                index={index}
                                onClick={handleTop10ChartClick}
                              />
                            ))}
                        </HorizontalScrollContainer>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          <div className="my-10"></div>
        </div>
      </div>
    </Suspense>
  );
}
