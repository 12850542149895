import { useState } from "react";
import logo from "../assets/logos/kazang-wordmark-white.png";
import MobileNavItem from "./mobile-nav-item";
import { countries as charts, genres } from "../constants/constants";
import { TbDotsVertical, TbMenu2, TbX } from "react-icons/tb";
import africa from "../assets/icons/africa.png";
import eu from "../assets/icons/europe.png";
import northAmerica from "../assets/icons/north-america.png";
import asiaPacific from "../assets/icons/asia-pacific.png";
import southAmerica from "../assets/icons/south-america.png";
import genre from "../assets/icons/genre.png";
import Flag from "react-flagkit";
import { Link } from "react-router-dom";
import GenreItem from "./genre-item";

const srcMapping = {
  africa: africa,
  "north-america": northAmerica,
  "asia-pacific": asiaPacific,
  "south-america": southAmerica,
  europe: eu,
  genres: genre
};

const mobileNavBarDropdowns = {
  ...charts,
  genres
};

const MobileMenu = ({ userData, navigate }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isKebabExpanded, setKebabIsExpanded] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeDropdownList, setActiveDropdownList] = useState([]);
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    setActiveDropdownList([]);
  };
  const toggleKebab = () => {
    setKebabIsExpanded(!isKebabExpanded);
    setActiveDropdownList([]);
  };
  const handleContinentActivate = continent => {
    if (continent === activeDropdown) {
      // user tapped an already active continent. probably
      // trying to minimize
      closeContientDropdown();
      return;
    }
    setIsExpanded(false);
    setActiveDropdown(continent);
    setActiveDropdownList(mobileNavBarDropdowns[continent]);
  };

  const closeContientDropdown = () => {
    setActiveDropdownList([]);
    setActiveDropdown(null);
  };

  return (
    <div id="mobile-menu" className="md:hidden fixed w-full z-20">
      <div className="flex items-center justify-between py-4 px-1 bg-neutral-800 relative">
        <TbMenu2 onClick={toggleExpanded} className="text-white text-4xl" />
        <img src={logo} alt="Kazang K Logo Fill" className="h-8" />
        <TbDotsVertical onClick={toggleKebab} className="text-white text-4xl" />
        {isKebabExpanded && (
          <div
            id="kebab-block"
            className="absolute z-20 top-3/4 bg-neutral-700 h-auto w-auto min-w-50 right-2 flex flex-col px-6 py-3 rounded-md gap-2"
          >
            {userData && userData.subActive && (
                <button
                    onClick={() => {
                      navigate("/subscription");
                    }}
                    className={`${!userData?.subActive ??
                    "hidden"} border-0 py-3 px-4 bg-white rounded-3xl`}
                >
                  Subscribe
                </button>
            )}
            {userData && !userData.subActive &&(
                <button
                    onClick={() => {
                      navigate("/subscription");
                    }}
                    className={`${userData?.subActive ??
                    "hidden"} border-0 py-3 px-4 bg-white rounded-3xl`}
                >
                  Unsubscribe
                </button>
            )}
            {userData && (
              <button
                onClick={() => {
                  navigate("/admin");
                }}
                className={`${userData?.isAdmin ??
                  "hidden"} text-white py-3 px-4 rounded cursor-pointer select-none`}
              >
                Admin Console
              </button>
            )}
            <button
              className="border-0 py-3 px-4 border-none text-red-600 font-medium"
              onClick={() => navigate("/logout")}
            >
              Logout
            </button>
          </div>
        )}
      </div>
      <div className="w-full h-auto bg-neutral-800 flex justify-between px-4 py-4 border-b">
        {Object.keys(mobileNavBarDropdowns).map((continent, index) => {
          return (
            <img
              className="h-10 w-10"
              key={continent}
              src={srcMapping[continent]}
              alt={`${continent} icon`}
              onClick={() => handleContinentActivate(continent)}
            />
          );
        })}
      </div>
      {activeDropdownList.length > 0 && (
        <div
          id="activeChartList"
          className={`
            absolute w-11/12 z-30 left-2
            bg-neutral-800
            transition-all duration-300
            border-2 border-neutral-500 rounded-md
            mt-0 mx-2 overflow-hidden px-4 py-2`}
        >
          <div className="flex flex-row items-center justify-between text-white text-2xl font-semibold">
            <h3 className="capitalize">
              {activeDropdown.replace("-", " ").replace(".", "")}
            </h3>
            <TbX onClick={closeContientDropdown} />
          </div>
          {activeDropdownList.map((item, index) => {
            return (
              <Link
                to={`/`}
                key={item.id ?? index}
                state={{ id: item.id }}
                onClick={closeContientDropdown}
              >
                <div
                  className={`bg-neutral-800 py-3 cursor-pointer
                flex gap-2 w-full items-center
                text-lg text-white`}
                >
                  {item.shortCode ? (
                    <>
                      <Flag country={item.shortCode} size={20}></Flag>
                      {item.countryLabel}
                    </>
                  ) : (
                    <>
                      <GenreItem item={item} isMobile={true} />
                    </>
                  )}
                </div>
              </Link>
            );
          })}
          ;
        </div>
      )}
      <ul
        className={`
        ${isExpanded ? "translate-x-0" : "-translate-x-full"}
        flex flex-col w-full
        items-start text-white
        gap-5 px-4 py-4 h-lvh
        transition-all duration-300 absolute
        border-t-2 border-neutral-500
        bg-neutral-800 z-20`}
      >
        <MobileNavItem path={"/"} icon={"TbHome2"} text={"Home"} />
        <MobileNavItem path={"/search"} icon={"TbSearch"} text={"Search"} />
        <MobileNavItem
          path={"/profile/me"}
          icon={"TbUserCircle"}
          text={"My Profile"}
        />
        <MobileNavItem path={"/friends"} icon={"TbUsers"} text={"Friends"} />
        <MobileNavItem
          path={"/playlists"}
          icon={"TbPlaylist"}
          text={"Playlists"}
        />
        <MobileNavItem
          path={"/add-song"}
          icon={"TbMusicPlus"}
          text={"Add a song"}
        />
        <MobileNavItem path={"/library"} icon={"TbLibrary"} text={"Library"} />
        <MobileNavItem path={"/"} icon={"TbCompass"} text={"Explore"} />

        <div className="my-4" />
        <MobileNavItem path={"/faqs"} icon={"TbQuestionMark"} text={"FAQs"} />
        <MobileNavItem
          path={"/about"}
          icon={"TbInfoCircle"}
          text={"About KaZang"}
        />
        <MobileNavItem path={"/settings"} icon={"TbLogout"} text={"Logout"} />
      </ul>
    </div>
  );
};

export default MobileMenu;
